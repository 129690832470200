<template>
  <div class="shadow bg-white q-py-sm text-center text-subtitle1 rounded">
    <strong>{{ $t('Order') + ': ' + data.id + ` (${data.created})` }}</strong>
  </div>
</template>

<script>
export default {
  name: 'PickingOrder',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
